/* latin */
@font-face {
  font-family: '__Allerta_Stencil_d62685';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1d8c539aeefaf5c6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Allerta_Stencil_Fallback_d62685';src: local("Arial");ascent-override: 90.79%;descent-override: 21.65%;line-gap-override: 0.00%;size-adjust: 113.69%
}.__className_d62685 {font-family: '__Allerta_Stencil_d62685', '__Allerta_Stencil_Fallback_d62685';font-weight: 400;font-style: normal
}.__variable_d62685 {--font-sans: '__Allerta_Stencil_d62685', '__Allerta_Stencil_Fallback_d62685'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Oswald_a11bf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/ed1e37f6db895491-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Oswald_a11bf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/b53ad041500dc2f5-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Oswald_a11bf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/0eb8e4ebb2c6cf71-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Oswald_a11bf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/4309b0ac5cd4bba8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Oswald_a11bf1';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/52fdb4d986ee0bca-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Oswald_Fallback_a11bf1';src: local("Arial");ascent-override: 146.51%;descent-override: 35.49%;line-gap-override: 0.00%;size-adjust: 81.43%
}.__className_a11bf1 {font-family: '__Oswald_a11bf1', '__Oswald_Fallback_a11bf1';font-weight: 200;font-style: normal
}.__variable_a11bf1 {--font-sans: '__Oswald_a11bf1', '__Oswald_Fallback_a11bf1'
}

